import { Breadcrumb, Card, TableNextPage, TablePage, TablePreviousPage } from "@sobrus-com/sobrus-design-system-v2";
import { axiosGet } from "API/API";
import NotifCard from "components/notifCard/NotifCard";
import { NotifContext } from "context/NotifContext";
import React, { useContext, useEffect, useState } from "react";
import useTablePagination from "utils/useTablePagination";

const Notifications = () => {
    const { notifs, setNotifs }: any = useContext(NotifContext);
    const [data, setData] = useState<any>(notifs);
    const [isFetching, setIsFetching] = useState(false);
    // const [filteredOrders, setfilteredOrders] = useState(null);
    // const [toggle, setToggle] = useState(false);
    // const [orderBy, setOrderBy] = useState(null);
    // const [direc, setDirec] = useState(null);

    const { pageNumber, setPageNumber, prevPage, nextPage } = useTablePagination(data, () => {}, setIsFetching);

    useEffect(() => {
        if (notifs?.notifications?.length > 0) {
            setData(notifs?.notifications);
        }
    }, [notifs]);

    useEffect(() => {
        if (isFetching) {
            axiosGet(`api/notifications?limit=15&page=${pageNumber}`).then((res) => {
                setData(res?.data?.notifications);
                setIsFetching(false);
            });
        }
    }, [isFetching]);

    return (
        <section className="sns-wrapper">
            <div className="sns-header">
                <Breadcrumb title="Liste des notifications" subTitle="Notifications" />
            </div>
            <Card>
                {data?.length > 0 &&
                    data.map((ele: any) => (
                        <NotifCard
                            key={ele?.id}
                            notifId={ele?.id}
                            isSeen={ele?.isSeen}
                            isClicked={ele?.isClicked}
                            content={ele?.content}
                            context={ele?.context}
                            action={ele?.action}
                            resourceId={ele?.resourceId}
                            time={ele?.createdAt}
                            setNotifs={setNotifs}
                        />
                    ))}
                <div className="notifications-paging">
                    <TablePreviousPage disabled={pageNumber <= 1} onClick={prevPage} />
                    <TablePage>{pageNumber}</TablePage>
                    <TableNextPage disabled={!data || data?.length < 15} onClick={nextPage} />
                </div>
            </Card>
        </section>
    );
};

export default Notifications;
