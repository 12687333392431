import { configureStore } from "@reduxjs/toolkit";
import documentInfosReducer from "./documentInfosSlice";

export const store = configureStore({
    reducer: {
        documentInfos: documentInfosReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
