import axios from "axios";
import { Navigate } from "react-router-dom";
import { API_URL } from "../env";

export const axiosGet = async (suffix, headers) => {
    try {
        const response = await axios.get(API_URL + suffix, { withCredentials: true, ...headers });
        return response;
    } catch (error) {
        console.error(error);
        // console.log('sdsd', error?.response);
        if (error?.response?.message === "code1" && suffix !== "authenticatedUser") {
            window.location.reload();
        } else if (error?.response?.status === 404) {
            // <Navigate to="/404" replace={true} />;
            // window.location.replace("/404");
        } else if (
            error?.response?.status === 401 &&
            error?.response?.data?.message === "Full authentication is required to access this resource."
        ) {
            window.location.replace("/denied_access");
        }
        // return error;
    }
};

export const axiosPost = async (suffix, body, headers, countryVerification) => {
    try {
        const response = await axios.post(API_URL + suffix, body, { withCredentials: true, headers: headers });
        if (!headers) {
            return response;
        } else return response;
    } catch (error) {
        console.error(error);
        if (error?.response?.message === "code1" && !countryVerification) {
            window.location.reload();
        }
        return error;
    }
};

export const axiosDelete = async (suffix, body, headers) => {
    try {
        const response = await axios.delete(API_URL + suffix, { withCredentials: true, headers: headers });
        if (!headers) {
            return response;
        } else return response;
    } catch (error) {
        console.error(error);
        if (error?.response?.message === "code1") {
            window.location.reload();
        }
        return error;
    }
};

export const axiosPut = async (suffix, body, headers) => {
    try {
        const response = await axios.put(API_URL + suffix, body, { withCredentials: true, headers: headers });
        if (!headers) {
            return response;
        } else return response;
    } catch (error) {
        console.error(error);
        return error;
        // if (error.response.status === 401) {
        //     localStorage.removeItem("isLogin");
        //     window.location.reload();
        // }
    }
};

export const axiosPatch = async (suffix, body, headers) => {
    try {
        const response = await axios.patch(API_URL + suffix, body, { withCredentials: true, headers: headers });
        if (!headers) {
            return response;
        } else return response;
    } catch (error) {
        console.error(error);
        if (error?.response?.message === "code1") {
            window.location.reload();
        }
        return error;
        // if (error.response.status === 401) {
        //     localStorage.removeItem("isLogin");
        //     window.location.reload();
        // }
    }
};
