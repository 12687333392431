import TransfertCreation from "pages/transferts-entrepot/transfertCreation/TransfertCreation";
import { lazy } from "react";

// Settings
const Profil = lazy(() => import("../pages/settings/Profil"));

const HistoriqueConnexion = lazy(() => import("../pages/settings/HistoriqueConnexion"));
const GestionEntrepots = lazy(() => import("../pages/settings/GestionEntrepots/GestionEntrepots"));
const GestionStock = lazy(() => import("pages/settings/GestionEntrepots/GestionStock"));
const Users = lazy(() => import("../pages/settings/Users/Users"));
const Emails = lazy(() => import("../pages/settings/Emails"));
const NotifsEmail = lazy(() => import("pages/settings/notifsEmail/NotifsEmail"));
const InfoFournisseur = lazy(() => import("../pages/settings/InfoFournisseur"));
const Roles = lazy(() => import("pages/settings/Acl/Roles/Roles"));
const Actions = lazy(() => import("pages/settings/Acl/Actions/Actions"));
const AllowedActions = lazy(() => import("pages/settings/Acl/Roles/AllowedActions"));
const EmailsOutList = lazy(() => import("pages/settings/EmailsOutList/EmailsOutList"));
const EmailsOutModeleAddOrUpdate = lazy(() => import("pages/settings/EmailsOutList/EmailsOutModeleAddOrUpdate"));
const Zones = lazy(() => import("pages/settings/zone/Zones"));
const AddProductsToZone = lazy(() => import("pages/settings/zone/AddProductsToZone"));
const Terms = lazy(() => import("pages/settings/Models/Terms"));
const OfferDetails = lazy(() => import("pages/settings/Models/OfferDetails"));
const PDFCreation = lazy(() => import("pages/settings/Models/pdfSettings/PDFCreation"));
const PDFView = lazy(() => import("pages/settings/Models/pdfSettings/PDFView"));
const DeliveryZones = lazy(() => import("pages/settings/deliveryZones/DeliveryZones"));
const AddClientsToZone = lazy(() => import("pages/settings/deliveryZones/AddClientsToZone"));
const KeyboardShortcuts = lazy(() => import("pages/settings/keyboardShortcuts/KeyboardShortcuts"));
const DiscountsList = lazy(() => import("pages/settings/discounts/DiscountsList"));
const DiscountHistory = lazy(() => import("pages/settings/discounts/DiscountHistory"));
const DiscountsUpdate = lazy(() => import("pages/settings/discounts/DiscountsUpdate"));
const DiscountsByClient = lazy(() => import("pages/settings/discountsByClient/DiscountsByClient"));
const CancellationReasons = lazy(() => import("pages/settings/CancellationReasons/CancellationReasons"));
const TransactionSettings = lazy(() => import("pages/settings/TransactionSettings/TransactionSettings"));
const TransactionSettingsAddOrUpdate = lazy(() =>
    import("pages/settings/TransactionSettings/TransactionSettingsAddOrUpdate")
);

// Demands
const Requests = lazy(() => import("pages/Requests/Requests"));
const RequestDetails = lazy(() => import("pages/Requests/RequestDetails/RequestDetails"));
const RequestAddOrUpdate = lazy(() => import("pages/Requests/RequestAddOrUpdate/RequestAddOrUpdate"));

const Rapport = lazy(() => import("pages/rapports/Rapports"));
const MarketplaceReport = lazy(() => import("pages/rapports/marketplaceReport/MarketplaceReport"));
const Ventes = lazy(() => import("pages/rapports/Ventes"));
const Achat = lazy(() => import("pages/rapports/Achat"));
const Client = lazy(() => import("pages/rapports/Client"));
const ClientPayments = lazy(() => import("pages/rapports/ClientPayments"));
const Produit = lazy(() => import("pages/rapports/Produit"));
const ChiffreAffaires = lazy(() => import("pages/rapports/ChiffreAffaires"));
const ChiffreAffairesClient = lazy(() => import("pages/rapports/ChiffreAffairesClient"));
const RapportSurProduit = lazy(() => import("pages/rapports/RapportSurProduit"));
const LogsErreurs = lazy(() => import("pages/rapports/LogsErreurs"));
const PertesEtManque = lazy(() => import("pages/rapports/PertesEtManque"));

const Stock = lazy(() => import("pages/stock/Stock"));
const StockImportsHistory = lazy(() => import("pages/stock/stockImports/StockImportsHistory"));
const StockImport = lazy(() => import("pages/stock/stockImports/StockImport"));
const StockImportDetails = lazy(() => import("pages/stock/stockImports/StockImportDetails"));

const Inventaires = lazy(() => import("pages/inventaires/Inventaires"));
const InventaireAddOrUpdate = lazy(() => import("pages/inventaires/InventaireAddOrUpdate"));
const InventaireDetails = lazy(() => import("pages/inventaires/InventaireDetails"));
const TransfertsEntrepot = lazy(() => import("pages/transferts-entrepot/TransfertsEntrepot"));
const TransfertsEntrepotDetails = lazy(() => import("pages/transferts-entrepot/TransfertsEntrepotDetails"));
// Commande client
const CommandesClients = lazy(() => import("pages/ventes/CommandesClients/CommandesClients"));
const ScanProducts = lazy(() => import("pages/ventes/CommandesClients/barcodeScan/ScanProducts"));
const ScanProductLB = lazy(() => import("pages/ventes/BonDeLivraison/barcodeScan/ScanProducts"));
// const CommandesClientsAddOrUpdate = lazy(() =>
//     import("pages/ventes/CommandesClients/CommandesClientsAddOrUpdate/CommandesClientsAddOrUpdate")
// );
const ClientOrdersCreation = lazy(() =>
    import("pages/ventes/CommandesClients/ClientOrdersCreation/ClientOrdersCreation")
);

const CommandesClientsDetails = lazy(() => import("pages/ventes/CommandesClients/CommandesClientsDetails"));
// Bon de livraison
const BonDeLivraison = lazy(() => import("pages/ventes/BonDeLivraison/BonDeLivraison"));
const ShippingTable = lazy(() => import("pages/ventes/BonDeLivraison/ShippingTable"));
const BonDeLivraisonAddOrUpdate = lazy(() =>
    import("pages/ventes/BonDeLivraison/BonDeLivraisonAddOrUpdate/BonDeLivraisonAddOrUpdate")
);
const BonDeLivraisonDetails = lazy(() => import("pages/ventes/BonDeLivraison/BonDeLivraisonDetails"));
const Factures = lazy(() => import("pages/ventes/Factures/Factures"));
const FacturesAddOrUpdate = lazy(() => import("pages/ventes/Factures/FacturesAddOrUpdate/FacturesAddOrUpdate"));
const FacturesDetails = lazy(() => import("pages/ventes/Factures/FacturesDetails"));
// const InvoiceDiscount = lazy(() => import("pages/ventes/Factures/InvoiceDiscount"));
const InvoiceDiscountPdf = lazy(() => import("pages/ventes/Factures/InvoiceDiscountPdf"));
const AddPayment = lazy(() => import("pages/ventes/Factures/AddPayment"));
const PrintInvoicesList = lazy(() => import("pages/ventes/Factures/PrintInvoicesList"));
const RelatedBLsListPDFs = lazy(() => import("pages/ventes/Factures/RelatedBLsListPDFs"));

// bordereaux
const Bordereaux = lazy(() => import("pages/ventes/bordereaux/Bordereaux"));
const BordereauxDetails = lazy(() => import("pages/ventes/bordereaux/BordereauxDetails"));
const BordereauxPDF = lazy(() => import("pages/ventes/bordereaux/PDFGenerator2"));
const BordereauxCreation = lazy(() => import("pages/ventes/bordereaux/bordereauxCreation/BordereauxCreation"));
// bordereaux Check
const BordereauxCheck = lazy(() => import("pages/ventes/bordereauxCheck/BordereauxCheck"));
const BordereauxCheckDetails = lazy(() => import("pages/ventes/bordereauxCheck/BordereauxCheckDetails"));
const BordereauxCheckCreation = lazy(() =>
    import("pages/ventes/bordereauxCheck/bordereauxCheckCreation/BordereauxCheckCreation")
);
// avoir client
const AvoirsClient = lazy(() => import("pages/ventes/AvoirsClient/AvoirsClient"));
const AvoirsClientAddOrUpdate = lazy(() =>
    import("pages/ventes/AvoirsClient/AvoirsClientAddOrUpdate/AvoirsClientAddOrUpdate")
);
const AvoirsClientDetails = lazy(() => import("pages/ventes/AvoirsClient/AvoirsClientDetails"));
const BonDeRetour = lazy(() => import("pages/ventes/BonDeRetour/BonDeRetour"));
const BonDeRetourAddOrUpdate = lazy(() =>
    import("pages/ventes/BonDeRetour/BonDeRetourAddOrUpdate/BonDeRetourAddOrUpdate")
);
const BonDeRetourDetails = lazy(() => import("pages/ventes/BonDeRetour/BonDeRetourDetails"));
const ClientDetails = lazy(() => import("pages/clients/ClientDetails/ClientDetails"));
const AvoirFinancierCreate = lazy(() => import("pages/clients/ClientDetails/avoirFinancier/AvoirFinancierCreate"));
const AvoirFinancierDetails = lazy(() => import("pages/clients/ClientDetails/avoirFinancier/AvoirFinancierDetails"));

const Clients = lazy(() => import("pages/clients/Clients"));
const ClientAddOrUpdate = lazy(() => import("pages/clients/ClientAddOrUpdate"));
const ClientReleveDeFactures = lazy(() => import("pages/clients/ClientReleveDeFactures/ClientReleveDeFactures"));
const ClientAjoutePayment = lazy(() => import("pages/clients/ClientAjoutePayment/ClientAjoutePayment"));

const Quotation = lazy(() => import("pages/Quotation/Quotation"));
const QuotationCreation = lazy(() => import("pages/Quotation/QuotationCreation/QuotationCreation"));
const QuotationDetails = lazy(() => import("pages/Quotation/QuotationDetails/QuotationDetails"));

export const NavPaths2 = [
    // //   {/* clients */}
    {
        path: "/clients",
        Component: Clients,
        canDoes: ["get_clients"],
    },
    {
        path: "/clients/creation",
        Component: ClientAddOrUpdate,
        canDoes: ["add_client", "get_accepted_payment_methods"],
    },
    {
        path: "/clients/creation/:id",
        Component: ClientAddOrUpdate,
        canDoes: ["edit_client_info", "get_accepted_payment_methods", "get_single_client"],
    },
    {
        path: "/clients/details/:id",
        Component: ClientDetails,
        canDoes: ["get_single_client"],
    },
    {
        path: "/clients/details/:id/financial",
        Component: AvoirFinancierCreate,
        canDoes: ["get_single_client"],
    },
    {
        path: "/clients/details/:id/financial/:finId",
        Component: AvoirFinancierDetails,
        canDoes: ["get_single_client"],
    },
    /**
     * TODO: ACL
     */
    {
        path: "/clients/details/:id/releve-de-factures",
        Component: ClientReleveDeFactures,
        canDoes: ["get_all_client_invoices"],
    },
    {
        path: "/clients/details/:id/ajouter-paiement",
        Component: ClientAjoutePayment,
        canDoes: ["get_all_client_invoices", "get_accepted_payment_methods", "add_client_payments"],
    },
    //  {/* "transferts-entrepot */}
    {
        path: "/transferts-entrepot",
        Component: TransfertsEntrepot,
        canDoes: ["get_all_stock_transfers"],
        premium: true,
    },
    {
        path: "/transferts-entrepot/creation",
        Component: TransfertCreation,
        canDoes: ["post_stock_transfers", "get_sobrus_products"],
        premium: true,
    },
    {
        path: "/transferts-entrepot/creation/:id",
        Component: TransfertCreation,
        canDoes: ["get_by_id_stock_transfers", "edit_stock_transfers", "get_sobrus_products"],
        premium: true,
    },
    {
        path: "/transferts-entrepot/details/:id",
        Component: TransfertsEntrepotDetails,
        canDoes: ["get_by_id_stock_transfers"],
        premium: true,
    },
    {
        path: "/inventaires",
        Component: Inventaires,
        canDoes: ["get_inventories_list"],
        premium: true,
    },
    {
        path: "/inventaires/creation",
        Component: InventaireAddOrUpdate,
        canDoes: ["add_inventories"],
        premium: true,
    },
    {
        path: "/inventaires/creation/:id",
        Component: InventaireAddOrUpdate,
        canDoes: ["get_single_inventory", "update_inventories"],
        premium: true,
    },
    {
        path: "/inventaires/details/:id",
        Component: InventaireDetails,
        canDoes: ["get_single_inventory"],
        premium: true,
    },
    //  {/* stock */}
    {
        path: "/stock",
        Component: Stock,
        canDoes: ["get_stocks"],
        premium: true,
    },
    {
        path: "/stock/import-history",
        Component: StockImportsHistory,
        canDoes: ["get_stocks"],
        premium: true,
    },
    {
        path: "/stock/import",
        Component: StockImport,
        canDoes: ["get_stocks"],
        premium: true,
    },
    {
        path: "/stock/import/details/:id",
        Component: StockImportDetails,
        canDoes: ["get_stocks"],
        premium: true,
    },
    // {/* ---Demandes */}
    {
        path: "/requests",
        Component: Requests,
        canDoes: ["get_all_demands"],
        premium: true,
    },
    {
        path: "/requests/details/:id",
        Component: RequestDetails,
        canDoes: ["get_demand_by_id"],
        premium: true,
    },
    {
        path: "/requests/addOrUpdate",
        Component: RequestAddOrUpdate,
        canDoes: ["add_demand"],
        premium: true,
    },
    {
        path: "/requests/addOrUpdate/:id",
        Component: RequestAddOrUpdate,
        canDoes: ["get_demand_by_id", "update_demand"],
        premium: true,
    },
    // {/* rapports */}
    {
        path: "/rapports",
        Component: Rapport,
    },
    {
        path: "/rapports/ventes",
        Component: Ventes,
        canDoes: ["get_sales_reports"],
        premium: true,
    },
    {
        path: "/rapports/achats",
        Component: Achat,
        canDoes: ["get_purchases_reports"],
        premium: true,
    },
    {
        path: "/rapports/produit",
        Component: Produit,
        canDoes: ["get_clients_reports"],
        premium: true,
    },
    {
        path: "/rapports/clients",
        Component: Client,
        canDoes: ["get_clients_reports"],
        premium: true,
    },
    {
        path: "/rapports/client-payments",
        Component: ClientPayments,
        canDoes: ["get_client_payments_reports"],
        premium: true,
    },
    {
        path: "/rapports/chiffre-affaires",
        Component: ChiffreAffaires,
        canDoes: ["get_turnovers_online_order_reports_by_status"],
    },
    {
        path: "/rapports/chiffre-affaires-client",
        Component: ChiffreAffairesClient,
        canDoes: ["get_turnovers_online_order_reports_by_client"],
    },
    {
        path: "/rapports/rapport-sur-produit",
        Component: RapportSurProduit,
        canDoes: ["get_turnovers_online_order_reports_by_product"],
        premium: true,
    },
    {
        path: "/rapports/marketplace",
        Component: MarketplaceReport,
        canDoes: [""],
        premium: true,
    },
    {
        path: "/rapports/logs-erreurs",
        Component: LogsErreurs,
        canDoes: ["get_online_order_errors_report"],
    },
    {
        path: "/rapports/pertes-et-manque",
        Component: PertesEtManque,
        canDoes: ["get_online_order_product_quatities_gap_report"],
    },
    //   {/* setings */}
    {
        path: "/parametres",
        Component: Profil,
    },
    {
        path: "/parametres/historique-de-connexion",
        Component: HistoriqueConnexion,
        canDoes: ["connection_history"],
    },
    {
        path: "/parametres/gestion-entrepots",
        Component: GestionEntrepots,
        canDoes: ["get_warehouses"],
        premium: true,
    },
    {
        path: "/parametres/stock",
        Component: GestionStock,
        canDoes: ["get_stocks"],
        premium: true,
    },
    {
        path: "/parametres/liste-des-utilisateurs",
        Component: Users,
        canDoes: ["get_tenant_users"],
    },
    {
        path: "/parametres/email",
        Component: Emails,
        canDoes: ["get_mail_params"],
    },
    {
        path: "/parametres/email-notifs",
        Component: NotifsEmail,
        canDoes: ["get_mail_params"],
    },
    {
        path: "/parametres/commande-enligne",
        Component: InfoFournisseur,
        canDoes: ["get_single_supplier_settings"],
    },
    {
        path: "/parametres/roles",
        Component: Roles,
        canDoes: ["get_all_roles"],
    },
    {
        path: "/parametres/actions",
        Component: Actions,
        canDoes: ["get_all_methods"],
    },
    {
        path: "/parametres/allowed-actions/:id",
        Component: AllowedActions,
        canDoes: ["get_all_methods", "get_single_role_by_id", "add_role_methods"],
    },
    {
        path: "/parametres/emails-out",
        Component: EmailsOutList,
        canDoes: ["get_all_methods", "get_single_role_by_id", "add_role_methods"],
    },
    {
        path: "/parametres/emails-out/modele-creation",
        Component: EmailsOutModeleAddOrUpdate,
        canDoes: ["get_all_methods", "get_single_role_by_id", "add_role_methods"],
    },
    {
        path: "/parametres/emails-out/modele-creation/:id",
        Component: EmailsOutModeleAddOrUpdate,
        canDoes: ["get_all_methods", "get_single_role_by_id", "add_role_methods"],
    },
    {
        path: "/parametres/zones",
        Component: Zones,
        canDoes: ["get_all_methods", "get_single_role_by_id", "add_role_methods"],
    },
    {
        path: "/parametres/zones/add-products",
        Component: AddProductsToZone,
        canDoes: ["get_all_methods", "get_single_role_by_id", "add_role_methods"],
    },
    {
        path: "/parametres/terms",
        Component: Terms,
        canDoes: ["get_all_methods", "get_single_role_by_id", "add_role_methods"],
    },
    {
        path: "/parametres/offer",
        Component: OfferDetails,
        canDoes: ["get_all_methods", "get_single_role_by_id", "add_role_methods"],
    },
    {
        path: "/parametres/pdf-view",
        Component: PDFView,
        canDoes: ["get_all_methods", "get_single_role_by_id", "add_role_methods"],
    },
    {
        path: "/parametres/pdf-view/edit",
        Component: PDFCreation,
        canDoes: ["get_all_methods", "get_single_role_by_id", "add_role_methods"],
    },
    {
        path: "/parametres/delivery-zones",
        Component: DeliveryZones,
        canDoes: ["get_all_methods", "get_single_role_by_id", "add_role_methods"],
    },
    {
        path: "/parametres/delivery-zones/clients",
        Component: AddClientsToZone,
        canDoes: ["get_all_methods", "get_single_role_by_id", "add_role_methods"],
    },
    {
        path: "/parametres/shortcuts",
        Component: KeyboardShortcuts,
        canDoes: ["get_all_methods", "get_single_role_by_id", "add_role_methods"],
    },
    {
        path: "/parametres/shortcuts",
        Component: KeyboardShortcuts,
        canDoes: ["get_all_methods", "get_single_role_by_id", "add_role_methods"],
    },
    {
        path: "/parametres/remises",
        Component: DiscountsList,
        canDoes: ["get_all_methods", "get_single_role_by_id", "add_role_methods"],
    },
    {
        path: "/parametres/remises/active",
        Component: DiscountsUpdate,
        canDoes: ["get_all_methods", "get_single_role_by_id", "add_role_methods"],
    },
    {
        path: "/parametres/remises/history",
        Component: DiscountHistory,
        canDoes: ["get_all_methods", "get_single_role_by_id", "add_role_methods"],
    },
    {
        path: "/parametres/remises-by-client",
        Component: DiscountsByClient,
        canDoes: ["get_all_methods", "get_single_role_by_id", "add_role_methods"],
    },
    {
        path: "/parametres/cancellation_reasons",
        Component: CancellationReasons,
        canDoes: ["get_clients_payments_cancellation_reasons"],
    },
    {
        path: "/parametres/transaction_settings/list",
        Component: TransactionSettings,
        canDoes: ["add_document_series"],
    },
    {
        path: "/parametres/transaction_settings/add_update",
        Component: TransactionSettingsAddOrUpdate,
        canDoes: ["add_document_series"],
    },
    {
        path: "/parametres/transaction_settings/add_update/:id",
        Component: TransactionSettingsAddOrUpdate,
        canDoes: ["update_document_series"],
    },
    // {/* Ventes */}
    // {/* ---clients orders */}
    {
        path: "/ventes/commandes-clients",
        Component: CommandesClients,
        canDoes: ["get_client_orders"],
    },
    {
        path: "/ventes/commandes-clients/scan",
        Component: ScanProducts,
        canDoes: ["get_client_orders"],
    },
    {
        path: "/ventes/commandes-clients/details/:id",
        Component: CommandesClientsDetails,
        canDoes: ["get_client_order_by_id"],
    },
    {
        path: "/ventes/commandes-clients/creation",
        Component: ClientOrdersCreation,
        canDoes: ["add_client_orders", "get_sobrus_products"],
    },
    // {
    //     path: "/ventes/commandes-clients/creation2",
    //     Component: ClientOrdersCreation,
    //     canDoes: ["add_client_orders", "get_sobrus_products"],
    // },
    {
        path: "/ventes/commandes-clients/creation/:id",
        Component: ClientOrdersCreation,
        canDoes: ["get_client_order_by_id", "get_sobrus_products", "edit_client_order_by_id"],
    },
    // {/* ---bon de livraison orders */}
    {
        path: "/ventes/bon-de-livraison",
        Component: BonDeLivraison,
        canDoes: ["get_all_client_delivery_notes"],
        premium: true,
    },
    {
        path: "/ventes/bon-de-livraison/expedition",
        Component: ShippingTable,
        canDoes: ["get_all_client_delivery_notes"],
        premium: true,
    },
    {
        path: "/ventes/bon-de-livraison/scan",
        Component: ScanProductLB,
        canDoes: ["get_client_orders"],
    },
    {
        path: "/ventes/bon-de-livraison/details/:id",
        Component: BonDeLivraisonDetails,
        canDoes: ["get_client_delivery_notes_by_id"],
        premium: true,
    },
    {
        path: "/ventes/bon-de-livraison/creation",
        Component: BonDeLivraisonAddOrUpdate,
        canDoes: ["post_client_delivery_note", "get_client_orders"],
        premium: true,
    },
    {
        path: "/ventes/bon-de-livraison/creation/:id",
        Component: BonDeLivraisonAddOrUpdate,
        canDoes: ["edit_client_delivery_notes", "get_client_orders", "get_client_delivery_notes_by_id"],
        premium: true,
    },
    // {/* ---bon de livraison orders */}
    {
        path: "/ventes/facture-client",
        Component: Factures,
        canDoes: ["get_all_client_invoices"],
        premium: true,
    },
    {
        path: "/ventes/facture-client/print",
        Component: PrintInvoicesList,
        canDoes: ["get_all_client_invoices"],
        premium: true,
    },
    {
        path: "/ventes/facture-client/bls-print",
        Component: RelatedBLsListPDFs,
        canDoes: ["get_all_client_invoices"],
        premium: true,
    },
    {
        path: "/ventes/facture-client/details/:id",
        Component: FacturesDetails,
        canDoes: ["get_client_invoice_by_id"],
        premium: true,
    },
    // page to delete
    // {
    //     path: "/ventes/facture-client/details/:id/discount",
    //     Component: InvoiceDiscount,
    //     canDoes: ["get_client_invoice_by_id"],
    //     premium: true,
    // },
    {
        path: "/ventes/facture-client/details/:id/discount-print",
        Component: InvoiceDiscountPdf,
        canDoes: ["get_client_invoice_by_id"],
        premium: true,
    },
    {
        path: "/ventes/facture-client/creation",
        Component: FacturesAddOrUpdate,
        canDoes: ["add_client_invoice", "get_all_client_delivery_notes"],
        premium: true,
    },
    {
        path: "/ventes/facture-client/creation/:id",
        Component: FacturesAddOrUpdate,
        canDoes: ["get_client_invoice_by_id", "edit_client_invoice", "get_all_client_delivery_notes"],
        premium: true,
    },
    {
        path: "/ventes/facture-client/paiement/:id",
        Component: AddPayment,
        canDoes: ["add_client_payments", "get_client_invoice_by_id", "get_accepted_payment_methods"],
        premium: true,
    },
    // {/* ---Bordereaux d'expédition */}
    {
        path: "/ventes/bordereaux",
        Component: Bordereaux,
        canDoes: ["get_all_client_issued_return"],
        premium: true,
    },
    {
        path: "/ventes/bordereaux/details/:id",
        Component: BordereauxDetails,
        canDoes: ["get_single_client_issued_return"],
        premium: true,
    },
    {
        path: "/ventes/bordereaux/details/:id/print",
        Component: BordereauxPDF,
        canDoes: ["get_single_client_issued_return"],
        premium: true,
    },
    {
        path: "/ventes/bordereaux/creation",
        Component: BordereauxCreation,
        canDoes: ["get_sobrus_products", "add_client_issued_return"],
        premium: true,
    },
    // {/* ---Bordereaux Check */}
    {
        path: "/ventes/bordereaux-cheque",
        Component: BordereauxCheck,
        canDoes: ["get_all_client_issued_return"],
        premium: true,
    },
    {
        path: "/ventes/bordereaux-cheque/details/:id",
        Component: BordereauxCheckDetails,
        canDoes: ["get_single_client_issued_return"],
        premium: true,
    },
    {
        path: "/ventes/bordereaux-cheque/creation",
        Component: BordereauxCheckCreation,
        canDoes: ["get_sobrus_products", "add_client_issued_return"],
        premium: true,
    },
    // {/* ---Avoirs client */}
    {
        path: "/ventes/avoirs-client",
        Component: AvoirsClient,
        canDoes: ["get_all_client_issued_return"],
        premium: true,
    },
    {
        path: "/ventes/avoirs-client/details/:id",
        Component: AvoirsClientDetails,
        canDoes: ["get_single_client_issued_return"],
        premium: true,
    },
    {
        path: "/ventes/avoirs-client/creation",
        Component: AvoirsClientAddOrUpdate,
        canDoes: ["get_sobrus_products", "add_client_issued_return"],
        premium: true,
    },
    {
        path: "/ventes/avoirs-client/creation/:id",
        Component: AvoirsClientAddOrUpdate,
        canDoes: ["get_sobrus_products", "get_single_client_issued_return", "edit_client_issued_return"],
        premium: true,
    },
    // {/* ---Bon de retour client */}
    {
        path: "/ventes/bon-de-retour",
        Component: BonDeRetour,
        canDoes: ["get_all_client_received_return"],
        premium: true,
    },
    {
        path: "/ventes/bon-de-retour/details/:id",
        Component: BonDeRetourDetails,
        canDoes: ["get_single_client_received_return"],
        premium: true,
    },
    {
        path: "/ventes/bon-de-retour/creation",
        Component: BonDeRetourAddOrUpdate,
        canDoes: ["get_sobrus_products", "add_client_received_return"],
        premium: true,
    },
    {
        path: "/ventes/bon-de-retour/creation/:id",
        Component: BonDeRetourAddOrUpdate,
        canDoes: ["get_sobrus_products", "get_single_client_received_return", "edit_client_received_return"],
        premium: true,
    },
    {
        path: "/ventes/quotation",
        Component: Quotation,
        canDoes: ["get_sobrus_products", "get_single_client_received_return", "edit_client_received_return"],
        premium: true,
    },
    {
        path: "/ventes/quotation/creation",
        Component: QuotationCreation,
        canDoes: ["get_sobrus_products", "get_single_client_received_return", "edit_client_received_return"],
        premium: true,
    },
    {
        path: "/ventes/quotation/creation/:id",
        Component: QuotationCreation,
        canDoes: ["get_sobrus_products", "get_single_client_received_return", "edit_client_received_return"],
        premium: true,
    },
    {
        path: "/ventes/quotation/details/:id",
        Component: QuotationDetails,
        canDoes: ["get_sobrus_products", "get_single_client_received_return", "edit_client_received_return"],
        premium: true,
    },
];
