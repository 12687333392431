let AUTH_CLIENT_ID = "10059";
let AUTH_REDIRECT_URL = "https://api.sns.sobrus.ovh/auth/token";
let AUTH_DOMAIN = "https://account.sobrus.ovh";
let API_URL = "https://api.sns.sobrus.ovh/";
let IMGS_PATH = "https://api.sns.sobrus.ovh/uploads/images/"; // + vehicles/
let ACCOUNT__LINK = "https://account.sobrus.ovh";
let BASE_URL = "https://api.sns.sobrus.ovh";
let PRODUCT_IMG_PATH = "https://ma.pharma.sobrus.com/uploads/ma/products/watermarked/";
let IMAGE_PATH = "https://api.marketplace.sobrus.ovh/uploaded_files/offers/images/";
let CDN_URL = "https://cdn.sobrus.ovh/";
// https://alpha-api-sns.k8s-admin.sobrus.ovh
if (process.env.REACT_APP_ENV === "stagingv2") {
    AUTH_REDIRECT_URL = "https://staging-api-sns.k8s-admin.sobrus.ovh/auth/k8s-admin/token";
    AUTH_CLIENT_ID = "10102";
    AUTH_DOMAIN = "https://account.sobrus.ovh";
    API_URL = "https://staging-api-sns.k8s-admin.sobrus.ovh/";
    IMGS_PATH = "https://staging-api-sns.k8s-admin.sobrus.ovh/uploads/images/"; // + vehicles/
    IMAGE_PATH = "https://api.marketplace.sobrus.ovh/uploaded_files/offers/images/";
    ACCOUNT__LINK = "https://account.sobrus.ovh";
    PRODUCT_IMG_PATH = "https://ma.pharma.sobrus.com/uploads/ma/products/watermarked/";
    BASE_URL = "https://staging-api-sns.k8s-admin.sobrus.ovh";
    CDN_URL = "https://cdn.sobrus.ovh/";
}

if (process.env.REACT_APP_ENV === "production") {
    AUTH_REDIRECT_URL = "https://api.sns.sobrus.com/auth/token";
    AUTH_DOMAIN = "https://account.sobrus.com";
    API_URL = "https://api.sns.sobrus.com/";
    IMGS_PATH = "https://api.sns.sobrus.com/uploads/images/"; // + vehicles/
    IMAGE_PATH = "https://api.marketplace.sobrus.com/uploaded_files/offers/images/";
    ACCOUNT__LINK = "https://account.sobrus.com";
    PRODUCT_IMG_PATH = "https://ma.pharma.sobrus.com/uploads/ma/products/watermarked/";
    BASE_URL = "https://api.sns.sobrus.com";
    CDN_URL = "https://sobruscdn.com/";
}

const AUTH_URL = `${AUTH_DOMAIN}/authorize?response_type=code&client_id=${AUTH_CLIENT_ID}&scope=openid%20offline_access%20profile%20email&redirect_uri=${AUTH_REDIRECT_URL}&prompt=login`;

export { API_URL, IMGS_PATH, AUTH_URL, ACCOUNT__LINK, BASE_URL, PRODUCT_IMG_PATH, IMAGE_PATH, CDN_URL };

// pour domaine name de l'api c'est : api.sns.sobrus.ovh
// domaine name du web c'est: sns.sobrus.ovh
// clientId: 10059
// redirectUri: api.sns.sobrus.ovh/auth/token
// mail d'utilisateur: redmoon7777@gamil.com
// pwd: qwqwqw12
// https://account.sobrus.ovh/authorize?response_type=code&client_id=10059&scope=openid%20offline_access%20profile%20email&redirect_uri=https://api.sns.sobrus.ovh/auth/token&prompt=login
